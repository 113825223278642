import "@daangn/sprout-css/button/index.css";
import "@daangn/sprout-css/spinner/index.css";

//@ts-ignore - yarn workspace exports map is not working correctly
import { InternalSpinner } from "@daangn/sprout-components-spinner/internal";
import type { ButtonElementType } from "@daangn/sprout-hooks-button";
import { useButton } from "@daangn/sprout-hooks-button";
import { useFocusVisible } from "@daangn/sprout-hooks-interactions";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { text } from "@seed-design/css/recipes/text";
import type { ElementType, Ref } from "react";
import * as React from "react";
import { match } from "ts-pattern";

import { spinnerVariant } from "./spinner";
import type { SeedBoxButtonProps } from "./types";

const getTypograpyClassName = (size: Required<SeedBoxButtonProps>["size"]) =>
  match(size)
    .with("xlarge", () => text({ textStyle: "t6Bold" }))
    .with("large", () => text({ textStyle: "t4Bold" }))
    .with("medium", () => text({ textStyle: "t4Bold" }))
    .with("small", () => text({ textStyle: "t4Bold" }))
    .with("xsmall", () => text({ textStyle: "t2Bold" }))
    .exhaustive();

const getSpinnerSize = (size: Required<SeedBoxButtonProps>["size"]) =>
  match(size)
    .with("xlarge", () => 18)
    .with("large", () => 14)
    .with("medium", () => 14)
    .with("small", () => 14)
    .with("xsmall", () => 12)
    .exhaustive();

const getSpinnerVariant = (variant: Required<SeedBoxButtonProps>["variant"]) =>
  match(variant)
    .with("primary", () => spinnerVariant.white)
    .with("primaryLow", () => spinnerVariant.primary)
    .with("secondary", () => spinnerVariant.black)
    .with("danger", () => spinnerVariant.white)
    .exhaustive();

const BoxButton = <T extends ButtonElementType>(
  props: SeedBoxButtonProps<T>,
  ref: Ref<HTMLElement>,
) => {
  const {
    elementType: Element = "button" as ElementType,
    size = "medium",
    variant = "primary",
    prefix,
    suffix,
    isLoading,
    children,
    ...otherProps
  } = props;
  const { buttonProps } = useButton(props);
  const { focusVisibleProps } = useFocusVisible();
  const { styleProps } = useStyleProps(otherProps);

  return (
    <Element
      ref={ref}
      {...styleProps}
      {...focusVisibleProps}
      {...buttonProps}
      data-size={size}
      data-variant={variant}
      data-loading={isLoading ? "" : undefined}
      className={composeClassName("seed-box-button", styleProps.className)}
    >
      <div data-part="content">
        {prefix && <span data-part="prefix">{prefix}</span>}
        <span className={getTypograpyClassName(size)}>{children}</span>
        {suffix && <span data-part="suffix">{suffix}</span>}
      </div>
      <div data-part="spinner-container">
        {isLoading && (
          <InternalSpinner
            size={getSpinnerSize(size)}
            {...getSpinnerVariant(variant)}
          />
        )}
      </div>
    </Element>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _BoxButton = React.forwardRef(BoxButton);
export { _BoxButton as BoxButton };

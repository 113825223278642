import "@daangn/sprout-css/chips/index.css";

import { useRadio } from "@daangn/sprout-hooks-radio-group";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { text } from "@seed-design/css/recipes/text";
import type { Ref } from "react";
import React from "react";

import { useRadioContext } from "./context";
import type { SeedChipRadioProps } from "./types";

const ChipRadio = (props: SeedChipRadioProps, ref: Ref<HTMLLabelElement>) => {
  const { state, size } = useRadioContext();
  const { children, prefix, suffix, ...otherProps } = props;
  const api = useRadio(otherProps, state);
  const { styleProps } = useStyleProps(otherProps);

  const variant = api.isSelected ? "emphasized" : "default";

  return (
    <label
      ref={ref}
      {...styleProps}
      {...api.radioProps}
      data-size={size}
      data-variant={variant}
      className={composeClassName("seed-chip", styleProps.className)}
    >
      <input {...api.radioInputProps} />
      {prefix && <div data-part="prefix">{prefix}</div>}
      <span
        {...api.radioLabelProps}
        className={text({ textStyle: "t4Regular" })}
      >
        {children}
      </span>
      {suffix && <div data-part="suffix">{suffix}</div>}
    </label>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _ChipRadio = React.forwardRef(ChipRadio);
export { _ChipRadio as ChipRadio };

import "@daangn/sprout-css/text-field/index.css";

import { useTextField } from "@daangn/sprout-hooks-text-field";
import { mergeRefs, useSafeLayoutEffect } from "@daangn/sprout-react-utils";
import { composeClassName, useStyleProps } from "@daangn/sprout-style-utils";
import { text } from "@seed-design/css/recipes/text";
import type { Ref } from "react";
import React from "react";

import { ErrorMessageIcon } from "./Icon";
import { getRestProps } from "./props";
import type { SeedMultilineTextFieldProps } from "./types";

const label3Bold = text({ textStyle: "t4Bold" });
const label3Regular = text({ textStyle: "t4Regular" });
const label2Regular = text({ textStyle: "t5Regular" });
const caption2Regular = text({ textStyle: "t2Regular" });

const MultilineTextField = (
  props: SeedMultilineTextFieldProps,
  ref: Ref<HTMLTextAreaElement>,
) => {
  const {
    label,
    isRequired,
    requiredIndicator,
    optionalIndicator,
    maxLength,
    hideCharacterCount,
    description,
    errorMessage,
    ...otherProps
  } = props;
  const {
    value,
    graphemes,
    isInvalid,
    rootProps,
    labelProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
  } = useTextField({ maxLength, ...otherProps, elementType: "textarea" });
  const { styleProps } = useStyleProps(otherProps);
  const restProps = getRestProps(otherProps);

  const showErrorMessage = isInvalid && !!errorMessage;
  const indicator = isRequired ? requiredIndicator : optionalIndicator;
  const showHint = !!description || (errorMessage && isInvalid);
  const renderCharacterCount = !hideCharacterCount && maxLength;
  const renderFoot = showHint || renderCharacterCount;
  const renderHead = label || indicator;

  // referenced from React Spectrum
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const onHeightChange = React.useCallback(() => {
    // Quiet textareas always grow based on their text content.
    // Standard textareas also grow by default, unless an explicit height is set.
    if (props.height && inputRef.current) {
      const input = inputRef.current;
      input.style.height = "";
    }
    if (!props.height && inputRef.current) {
      const input = inputRef.current;
      const prevAlignment = input.style.alignSelf;
      const prevOverflow = input.style.overflow;
      // Firefox scroll position is lost when overflow: 'hidden' is applied so we skip applying it.
      // The measure/applied height is also incorrect/reset if we turn on and off
      // overflow: hidden in Firefox https://bugzilla.mozilla.org/show_bug.cgi?id=1787062
      const isFirefox = "MozAppearance" in input.style;
      if (!isFirefox) {
        input.style.overflow = "hidden";
      }
      input.style.alignSelf = "start";
      input.style.height = "auto";
      // offsetHeight - clientHeight accounts for the border/padding.
      input.style.height = `${
        input.scrollHeight + (input.offsetHeight - input.clientHeight)
      }px`;
      input.style.overflow = prevOverflow;
      input.style.alignSelf = prevAlignment;
    }
  }, [inputRef, props.height]);

  useSafeLayoutEffect(() => {
    if (inputRef.current) {
      onHeightChange();
    }
  }, [onHeightChange, value, inputRef]);

  return (
    <div
      {...styleProps}
      {...rootProps}
      className={composeClassName(
        "seed-multiline-text-field",
        styleProps.className,
      )}
    >
      {renderHead && (
        <div data-part="head">
          {label && (
            <label {...labelProps} className={label3Bold}>
              {label}
            </label>
          )}
          {indicator && (
            <span data-part="indicator" className={label3Regular}>
              {indicator}
            </span>
          )}
        </div>
      )}
      <div data-part="field">
        <textarea
          rows={3}
          className={label2Regular}
          ref={mergeRefs(ref, inputRef)}
          {...restProps}
          {...inputProps}
        />
      </div>
      {renderFoot && (
        <div data-part="foot">
          {showErrorMessage ? (
            <span {...errorMessageProps} className={label3Regular}>
              {errorMessage && <ErrorMessageIcon />} {errorMessage}
            </span>
          ) : (
            <span {...descriptionProps} className={label3Regular}>
              {description}
            </span>
          )}
          {renderCharacterCount && (
            <div data-part="count-container" className={caption2Regular}>
              <span data-part="character-count">{graphemes.length}</span>
              <span data-part="max-count">/{maxLength}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const _MultilineTextField = React.forwardRef(MultilineTextField);
export { _MultilineTextField as MultilineTextField };

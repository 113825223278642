import type { PickedAttributes } from "./types";

export function getRestProps<
  T extends Partial<Record<PickedAttributes, unknown>>,
>(props: T): Pick<T, PickedAttributes> {
  return {
    onContextMenu: props.onContextMenu,
    onPaste: props.onPaste,
    onCopy: props.onCopy,
    onCut: props.onCut,
  };
}

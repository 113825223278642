import { vars } from "@seed-design/css/vars";

export const spinnerVariant = {
  primary: {
    trackColor: vars.$color.palette.carrot100,
    indicatorColor: vars.$color.palette.carrot600,
  },
  white: {
    trackColor: vars.$color.palette.staticWhiteAlpha200,
    indicatorColor: vars.$color.palette.staticWhite,
  },
  black: {
    trackColor: vars.$color.palette.gray200,
    indicatorColor: vars.$color.palette.gray1000,
  },
};
